.App {
  text-align: left;
  color: white;
  font-size: 1rem;
  /* background-color: black; */
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.img-construction {
  width: 500px;
}
@media (max-width: 576px){
  .img-construction {
    width: 80vw;
  }
}
.button_list {
  position: relative;
  margin-left: 4px;
  margin-right: 4px;
  
}

/* .circle_button:hover {
  background-color: rgb(148, 147, 150);
} */
.cr_icon {
  width: 35px;
  height: 35px;
  color: lightgrey;
  top:0px;
  left:0px;
}

.cr_icon:hover {
  /* filter: brightness(0.85); */
  scale: 1.2;
}
.bs_icon{
  color: white;
  width: 22px;
  height: 22px;
  position: absolute;
  top:6px;
  left:7px;
  
}

.fb_icon {
  color: rgb(13, 94, 235);
}

.img_right {
  float: right;
  margin: 5px;
  width: 300px;
}

.img_c {
  margin: 5px;
  width: 300px;
}

.photo_banner {
  position: relative;
  display: flex;
  width: 100vw;
  height: calc(100vw/4)
}
.photo_banner_sq.col-sm-3 {
  padding-left: 0px;
  padding-right: 0px;
}
.photo_banner_sq {
  /* position: absolute; */
  /* width: calc(100vw/4); */
  height: calc(100vw/4);
  /* padding-left: 0px; */
}

.banner_img {
  width: calc(100vw/4 - 2px);
  height: calc(100vw/4 - 2px);

  object-fit: cover;
  padding: 1px;
  filter: grayscale(100%)

}
.banner_img:hover {
  filter: grayscale(0%)
}

.black_div {
  width: 100vw;
  height: 500px;
  background-color: black;
}
.slide_frame {
  position: absolute;
  left: 8%;
  right: 8%;
  top: 5rem;
}
a.carousel-control-next, a.carousel-control-prev {
    width: 5%;
}
.carousel-indicators [data-bs-target] {
  border-radius: 50%;
  margin-right: 5px !important;;
  margin-left: 5px !important;;
  width: 15px !important;;
  height: 15px !important;;
  
  
}
.text1 {
  color: black;
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.text2 {
  visibility: visible;
}

@media (max-width: 900px){
  .img_c {
    /* position: absolute; */
    width: calc(100vw/3);
  }
}
@media (max-width: 767px){
  .img_right {
    /* position: absolute; */
    width: calc(100vw*300/767);
  }
}
@media (max-width: 576px){
  .App {
    text-align: center;
  }
  .banner_img {
    width: 80vw;
    height: 80vw;
  }
  .photo_banner_sq {
    height: calc(80vw);
  }
  .black_div {
    /* width: 100vw; */
    height: 700px;
  }
  .img_right {
    float: unset;
    /* margin: 5px; */
    width: 60vw;
  }
  .img_c {
    width: 60vw;
  }
  .slide_frame {
    left: 8%;
  right: 8%;
    top: 10px;
  }
  .text1 {
    display: grid;
  }
  .text2 {
    visibility:collapse;
    height: 0;
  }
}
